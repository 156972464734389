exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-auth-jsx": () => import("./../../../src/pages/auth.jsx" /* webpackChunkName: "component---src-pages-auth-jsx" */),
  "component---src-pages-case-studies-axil-design-system-jsx": () => import("./../../../src/pages/case-studies/axil-design-system.jsx" /* webpackChunkName: "component---src-pages-case-studies-axil-design-system-jsx" */),
  "component---src-pages-case-studies-myeverbright-data-monitoring-charts-jsx": () => import("./../../../src/pages/case-studies/myeverbright/data-monitoring-charts.jsx" /* webpackChunkName: "component---src-pages-case-studies-myeverbright-data-monitoring-charts-jsx" */),
  "component---src-pages-case-studies-myeverbright-jsx": () => import("./../../../src/pages/case-studies/myeverbright.jsx" /* webpackChunkName: "component---src-pages-case-studies-myeverbright-jsx" */),
  "component---src-pages-case-studies-myeverbright-real-time-power-flow-jsx": () => import("./../../../src/pages/case-studies/myeverbright/real-time-power-flow.jsx" /* webpackChunkName: "component---src-pages-case-studies-myeverbright-real-time-power-flow-jsx" */),
  "component---src-pages-case-studies-myeverbright-research-jsx": () => import("./../../../src/pages/case-studies/myeverbright/research.jsx" /* webpackChunkName: "component---src-pages-case-studies-myeverbright-research-jsx" */),
  "component---src-pages-case-studies-myeverbright-visual-language-jsx": () => import("./../../../src/pages/case-studies/myeverbright/visual-language.jsx" /* webpackChunkName: "component---src-pages-case-studies-myeverbright-visual-language-jsx" */),
  "component---src-pages-case-studies-quest-for-a-clean-house-jsx": () => import("./../../../src/pages/case-studies/quest-for-a-clean-house.jsx" /* webpackChunkName: "component---src-pages-case-studies-quest-for-a-clean-house-jsx" */),
  "component---src-pages-case-studies-search-feature-jsx": () => import("./../../../src/pages/case-studies/search-feature.jsx" /* webpackChunkName: "component---src-pages-case-studies-search-feature-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

